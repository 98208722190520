const config = {
  attachment: {
    accept: 'image/png, image/jpeg',
    size: 4194304,
    // bytes
    resize: {
      size: 550,
      // kilobytes
      width: 800,
      height: 800
    }
  },
  cookies: {
    contest: {
      name: 'msi_KpHxXA',
      expires: 2 // days
    }
  },

  calendar: {
    maxDays: 7
  },
  contest: {
    modal: true
  },
  finder: {
    minLength: 2
  },
  i18n: {
    defaultLanguage: 'fr',
    supportedLanguages: ['fr', 'en'],
    fallbackLanguage: 'fr',
    iso: {
      fr: 'fr_CA',
      en: 'en_CA'
    }
  },
  map: {
    defaultCenter: {
      lat: 46.4226158,
      lng: -72.0556231
    },
    defaultZoom: 8,
    geolocation: false,
    zooms: {
      region: 9,
      city: 12,
      district: 13,
      neighborhood: 14
    },
    maxZoom: 18,
    minZoom: 6,
    shape: {
      path: 'M36,36.1L36 0 0 0 0 36.1 13.8 36.1 18.2 40 22.2 36.1 z',
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: '#FFFFFF'
    },
    marker: {
      anchor: {
        x: 18,
        y: 20
      },
      scale: {
        default: 0.75,
        hover: 0.87
      }
    },
    color: {
      default: '#B2B1AD',
      project: '#1c1c1c',
      hover: '#E4392F'
    },
    cluster: {
      algorithm: {
        minPoints: 2,
        maxZoom: 17
      },
      label: {
        color: '#FFFFFF',
        fontSize: '14px'
      },
      origin: {
        x: 18,
        y: 18
      },
      anchor: {
        x: 18,
        y: 20
      },
      size: {
        width: 36,
        height: 40
      },
      scale: {
        default: 1,
        hover: 1.15
      }
    }
  },
  pagination: {
    blog: {
      token: 'page',
      perPage: 6
    },
    rentals: {
      token: 'page',
      perPage: 12
    }
  },
  query: {
    delimiters: {
      f: '-',
      p: '_',
      t: '_'
    }
  },
  request: {
    rental: {
      prefix: 'LR-'
    },
    visit: {
      prefix: 'VR-',
      nearby: true
    },
    work: {
      prefix: 'WR-'
    }
  },
  rentals: {
    b2b: ['commercial', 'warehouse', 'storage', 'outdoor-parking', 'indoor-parking']
  },
  search: {
    available: null,
    categories: [],
    coordinates: null,
    facilities: [],
    floor: [],
    keyword: '',
    locations: [],
    price: {
      min: 0,
      max: 9999
    },
    types: [],
    referer: null
  },
  storage: {
    keys: {
      search: 'msi_20230329_searchParams',
      work: 'msiWorkRequestData'
    },
    expires: 86400 // 1 day
  }
};

export default config;