import { useEffect } from 'react';
import { useLocation } from '@reach/router';
export function ScrollToTop() {
  const {
    pathname
  } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

/**
 * Post cover position on scroll
 */
export function postScrollPosition() {
  if (typeof window !== 'undefined') {
    if (document.getElementsByClassName('post-content__cover').length > 0) {
      const scrollTop = window.scrollY;
      const mainHeight = document.getElementsByTagName('main')[0].offsetHeight;
      const element = document.getElementsByClassName('post-content__cover')[0];
      const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
      if (window.innerWidth >= 768) {
        if (scrollTop >= 0 && scrollTop < mainHeight - window.innerHeight) {
          // Set fixed position
          element.classList.remove('absolute');
          element.classList.add('fixed');
          element.style.top = "".concat(headerHeight, "px");
        } else {
          // Set absolute position
          element.classList.remove('fixed');
          element.classList.add('absolute');
          element.style.top = "".concat(mainHeight - window.innerHeight + headerHeight, "px");
        }
      } else {
        element.style.top = '0px';
      }
    }
  }
}

/**
 * Rental cover position on scroll
 */
export function rentalScrollPosition() {
  let type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'rental';
  if (typeof window !== 'undefined') {
    const element = document.getElementsByClassName('rental-content__hero--picture')[0];
    const ctaContainer = document.getElementsByClassName('buttons');
    const unitsCta = document.getElementById('project-units');
    const scrollTop = window.scrollY;

    // Units cta position
    if (unitsCta) {
      const unitsContainer = document.getElementById('units');
      if (unitsContainer) {
        if (window.innerWidth <= 992) {
          if (unitsContainer.getBoundingClientRect().top < window.innerHeight) {
            unitsCta.classList.add('hide');
          } else {
            unitsCta.classList.remove('hide');
          }
        } else {
          unitsCta.classList.remove('hide');
        }
      }
    }

    // Gallery position
    if (element) {
      const phoneContainer = document.getElementsByClassName('project-content__hero--phone');
      let facilitiesContainer = document.getElementsByClassName("".concat(type, "-content__hero--facilities"))[0];
      if (phoneContainer.length) {
        facilitiesContainer = phoneContainer[0];
      }
      if (facilitiesContainer) {
        if (window.innerWidth >= 992) {
          if (facilitiesContainer.getBoundingClientRect().bottom > window.innerHeight) {
            element.classList.remove('absolute');
            element.classList.add('fixed');
          } else {
            element.classList.remove('fixed');
            element.classList.add('absolute');
          }
        } else {
          element.classList.remove('fixed');
          element.classList.remove('absolute');
        }
      }
    }

    // CTA
    if (ctaContainer.length && window.innerWidth < 992) {
      const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
      if (scrollTop > ctaContainer[0].getBoundingClientRect().bottom + headerHeight + ctaContainer[0].offsetHeight) {
        ctaContainer[0].classList.add('fixed');
      } else {
        ctaContainer[0].classList.remove('fixed');
      }
    }
  }
}

/**
 * Map position on scroll
 */
export function mapScrollPosition() {
  if (typeof window !== 'undefined') {
    if (document.getElementsByClassName('map-container').length > 0) {
      const scrollTop = window.scrollY;
      const mainHeight = document.getElementsByTagName('main')[0].offsetHeight;
      const element = document.getElementsByClassName('map-container')[0];
      const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
      if (window.innerWidth >= 768) {
        if (scrollTop >= 0 && scrollTop < mainHeight - window.innerHeight + headerHeight) {
          // Set fixed position
          element.classList.remove('absolute');
          element.classList.add('fixed');
          element.style.top = "".concat(headerHeight, "px");
        } else {
          // Set absolute position
          element.classList.remove('fixed');
          element.classList.add('absolute');
          element.style.top = "".concat(mainHeight - window.innerHeight + headerHeight * 2, "px");
        }
      } else {
        element.style.top = '0px';
      }
    }
  }
}

/**
 * Show/Hide map button
 * @type {void}
 */
export function mapScrollButton() {
  if (typeof window !== 'undefined') {
    const element = document.getElementsByClassName('rentals-content__scroll')[0];
    if (element) {
      const footer = document.getElementsByTagName('footer')[0];
      if (window.scrollY > window.innerHeight / 2 && footer.getBoundingClientRect().top - footer.offsetHeight > 0) {
        element.classList.add('show');
      } else {
        element.classList.remove('show');
      }
    }
  }
}

/**
 * FAQ navigation position on scroll
 */
export function faqNavScrollPosition() {
  if (typeof window !== 'undefined') {
    const element = document.getElementsByClassName('faq-nav__container')[0];
    const parentOffsetTop = window.innerWidth >= 992 ? 65 : 40;
    const contentOffsetBottom = 250 - parentOffsetTop;
    if (element) {
      const scrollTop = window.scrollY;
      const mainHeight = document.getElementsByTagName('main')[0].offsetHeight;
      const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
      const faqList = document.getElementsByClassName('faq-content__list')[0];
      const distanceFromTop = window.pageYOffset + faqList.getBoundingClientRect().top - headerHeight;
      if (window.innerWidth >= 768) {
        /**
         * Tablet / Desktop navigation
         * > 768
         */
        if (scrollTop >= distanceFromTop) {
          element.classList.remove('absolute');
          element.classList.add('fixed');
          element.style.width = "".concat(window.innerWidth >= 992 ? element.parentNode.clientWidth : element.parentNode.clientWidth - 55 * 2, "px");
          element.style.top = "".concat(headerHeight + parentOffsetTop, "px");
          if (scrollTop > mainHeight - window.innerHeight + headerHeight) {
            element.classList.remove('fixed');
            element.classList.add('absolute');
            element.style.top = null;
            element.style.bottom = "".concat(contentOffsetBottom, "px");
          }
        } else {
          element.classList.remove('fixed');
          element.classList.remove('absolute');
          element.style.top = null;
        }
      } else {
        /**
         * Mobule navigation
         * < 768
         */
        const faqNav = element.parentNode;
        if (scrollTop >= distanceFromTop) {
          faqNav.classList.add('fixed');
        } else {
          faqNav.classList.remove('fixed');
        }
      }
    }
  }
}

/**
 * Smooth scroll to position
 * @param  {integer} y Element top position
 */
export function smoothScroll(y) {
  if (typeof window !== 'undefined') {
    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  }
}

/**
 * Instantly scroll to position
 * @param  {integer} y Element top position
 */
export function instantlyScroll(y) {
  if (typeof window !== 'undefined') {
    window.scrollTo({
      top: y,
      behavior: 'auto'
    });
  }
}